import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
  selectedtype: string;
  otpNumber: string;
  time: number;
  isTimerRunning: boolean;
  token: string;
  isOpen: boolean;
  otpType: string;
  IsApplied: boolean;
  IsApproved: boolean;
  emailPhone: string;
  isError:boolean;
    errorotp:string;
  // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VerificationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  timerId: number | NodeJS.Timeout | null = null;
  sendOptId: string = "";
  verifyID: string = "";
  sendPhoneOtpId: string = "";
  appliedApprovedId:string=""
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
      selectedtype: "",
      otpNumber: '',
      time: 90,
      isTimerRunning: true,
      token: '',
      isOpen: false,
      otpType: "",
      IsApplied: false,
      IsApproved: false,
      emailPhone:"",
      isError:false,
      errorotp:""

      // Customizable Area End
        };

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AccoutLoginSuccess)
        ]
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      await storage.set("token", responseJson?.meta?.token);
      if (apiRequestCallId) {
        if (apiRequestCallId == this.sendPhoneOtpId) {
          this.setState({ token: responseJson.meta.token });
        } 
        if (apiRequestCallId && this.sendOptId) {
          await storage.set("token", responseJson?.token);
        } 
        if (apiRequestCallId == this.verifyID) {
          if (!responseJson.errors) {
            this.setState({ isOpen: true });
            this.handleForward();
          }
          else if(responseJson.errors){
              this.setState({ isError: true });
          }
        }
      }
    }
    // Customizable Area End
    }


  // Customizable Area Start

  handleTypeEmail = () => {
    this.setState({ selectedtype: "email" });
    storage.set("otp type", "email_otp");
  };
  handleTypePhone = () => {
    this.setState({ selectedtype: "phone" });
    storage.set("otp type", "sms_otp");
  };
  handleOtp = (otpnumber: string) => {
    this.setState({ otpNumber: otpnumber });
  };
  async componentDidMount(): Promise<void> {
    const IsApproved= await storage.get('IsApproved')
    if(IsApproved){
      this.setState({IsApproved:true, IsApplied:false})
    }
    const IsApplied=await storage.get('IsApplied')
    if(IsApplied){
      this.setState({IsApplied:true, IsApproved:false})
    }
        this.startTimer();
    this.handleName();
    return Promise.resolve();
  }
  handleName = async () => {
    const seletedClick = await storage.get("otp type");
    if (seletedClick == "email_otp") {const emailorphone = await storage.get("email");this.setState({ emailPhone: emailorphone });} else if (seletedClick == "sms_otp") {const emailorphone = await storage.get("phone");this.setState({ emailPhone: emailorphone });}
  }

  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState(
        (prevState) => ({ time: prevState.time - 1 }),
        () => {
          if (this.state.time === 0) {
            this.stopTimer();
            this.setState({ isTimerRunning: false });
          }
        }
      );
    }, 1000);
  }

  stopTimer(): void {
    if (this.timerId) {
      clearInterval(this.timerId as number);
    }
  }

  handleResendOtpClick = async (): Promise<void> => {
    this.setState({otpNumber:''})
    const seletedClick = await storage.get("otp type");
    if (seletedClick == "email_otp") {
      this.sendOtp();
    } else if (seletedClick == "sms_otp") {
      this.sendOtpPhone();
    }
    this.setState({ time: 90, isTimerRunning: true }, () => {
      this.startTimer();
    });
  };

  handleClick = async () => {
    if (
      this.state.selectedtype == "email" ||
      this.state.selectedtype == "phone"
    ) {
      const seletedClick = await storage.get("otp type");
      if (seletedClick == "email_otp") {
        this.sendOtp();
      } else if (seletedClick == "sms_otp") {
        this.sendOtpPhone();
      }

      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "OtpConfirmation" //screen name in app.tsx or app.js
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
      message.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
      this.send(message);
    }
  };

  sendOtp = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      data: {
        type: "email_otp",
        attributes: {
          email: await storage.get("email"),
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOptId = requestMessage.id;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/send_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleVerify = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      data: {
        type: await storage.get("otp type"),
        otp_code: this.state.otpNumber,
        token: await storage.get("token"),
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  backToVerification = () => {
    const messageType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    messageType.addData(
      getName(MessageEnum.NavigationTargetMessage), "Verification" //screen name in app.tsx or app.js
    );
    messageType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    messageType.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageType);
  };

  closeSnackbar = () => {
    this.setState({ isOpen: false, isError: false });
  };

  sendOtpPhone = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      data: {
        type: "sms_otp",
        attributes: {
          full_phone_number: await storage.get("phone"),
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPhoneOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/send_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlelogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  handleForward = () => {
    storage.set('IsApplied')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VerificationSentPending"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  handleBack=async ()=>{
    const role= await storage.get("selectedRole")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock' );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: role});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage); this.send(message);
  }

  handleAppliedApproved=async ()=>{
    const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };
      const email= await storage.get("email")
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.appliedApprovedId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/verify_email?email=${email}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
